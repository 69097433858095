import { providersPropsMap } from '../../contexts/main/providersPropsMap';
import { ScreenNames } from '../../contexts/main/biInterfaces';
import { Pages } from '../../contexts/Location/LocationContext';
import { ControllerParams, CreateControllerFn } from '@wix/yoshi-flow-editor';
import userTypeHandlers from '../../contexts/User/helpers/userTypeHandlers';

const createController: CreateControllerFn = async ({
  flowAPI,
}: ControllerParams) => {
  return {
    async pageReady() {
      const initialProps = await providersPropsMap({
        biSettings: {
          screenName: ScreenNames.PaymentPage,
        },
        flowAPI,
        enabledProviders: ['challengeDataProvider', 'participantProvider'],
      });

      try {
        flowAPI.controllerConfig.setProps({ ...initialProps });
      } catch (e) {
        console.info(e);
      }

      const slug = initialProps?.challengeData?.challenge?.settings?.seo?.slug;

      if (!initialProps.participant) {
        initialProps.goToPage({
          pageId: Pages.Details,
          challengeId: slug,
        });
        return;
      }
      const currentParticipantState =
        initialProps.participant.transitions[0].state;

      if (!userTypeHandlers.isInPaymentState(currentParticipantState)) {
        initialProps.goToPage({
          pageId: Pages.ThankYou,
          challengeId: slug,
        });
      }
    },
  };
};

export default createController;
